import { IEnvironmentConfig } from "./environment.model";

export const environment: IEnvironmentConfig = {
  production: true,
  envType: "dmu-master",
  useSentry: true,
  apiBaseUrl:
    "https://lm3ojs37x9.execute-api.eu-west-2.amazonaws.com/dmu-master/api",
  apiVersion: "v1",
  baseUrl: "https://pas.dmu.ac.uk",
  defaultCountryCode: "GB",
  momentLocale: "en-gb",
  momentDateFormat: "D-MMM-YYYY",
  momentDateTimeFormat: "lll",
  titlePrefix: "PAS",

  // dmu specific current filter
  currentStudentFilter: {
    type: "students",
    path: "currentCourses.current",
    operator: "eq",
    values: [true],
  },

  // sentry
  sentryDsn:
    "https://1dafbac7dc51c111c649e5606d3824e5@errors-temp.careerhub.support/4508999677575168",
  commitRef: "ab3796a545a5700093b40cb3b92ea92654c29e7f",

  defaultRegionList: [
    "Derbyshire",
    "Leicestershire",
    "Lincolnshire",
    "Northamptonshire",
    "Nottinghamshire",
    "Other",
  ],
};
